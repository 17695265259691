/**
 *
 * @returns {string}
 */
export const guid = function (){
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

/**
 *
 * @returns {number}
 */
export const uniqueNumber = function(){
    return Date.now() + Math.random()

};

/**
 *
 * @param regex
 * @param text
 * @returns {Array}
 */
export const getAllMatches = function(regex, text) {
    if (regex.constructor !== RegExp) {
        throw new Error('not RegExp');
    }
    let res = [];
    let match = null;

    if (regex.global) {
        while (match = regex.exec(text)) {
            res.push(match);
        }
    }
    else {
        if (match = regex.exec(text)) {
            res.push(match);
        }
    }
    return res;
};

/**
 *
 * @param str
 * @returns {boolean}
 * @constructor
 */
export const IsValidJSONString = function(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

/**
 *
 * @param arrArg
 * @returns {*}
 */
export const uniqArrayEs6 = (arrArg) => {
    return arrArg.filter((elem, pos, arr) => {
        return arr.indexOf(elem) == pos;
    });
};

export const computeIntHash = (input) =>{
    var hash = 0, len = input.length;
    for (var i = 0; i < len; i++) {
        hash  = ((hash << 5) - hash) + input.charCodeAt(i);
        hash |= 0; // to 32bit integer
    }
    return hash;
};

// based on : https://stackoverflow.com/questions/15523514/find-by-key-deep-in-a-nested-object
export const deepGetObjectfunction =  function(theObject,key) {
    var result = null;
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            result = deepGetObjectfunction(theObject[i],key);
            if (result) {
                break;
            }
        }
    }
    else
    {
        for(var prop in theObject) {
            //console.log(prop + ': ' + theObject[prop]);
            if(prop == key) {
                    return theObject[prop];
            }
            if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = deepGetObjectfunction(theObject[prop],key);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
};

export const flattenJSON = (obj, path = '') => {
    if (!(obj instanceof Object)) return {[path.replace(/\.$/g, '')]:obj};

    return Object.keys(obj).reduce((output, key) => {
        return obj instanceof Array ?
             {...output, ...flattenJSON(obj[key], path +  '[' + key + '].')}:
             {...output, ...flattenJSON(obj[key], path + key + '.')};
    }, {});
};

/**
 * fonction groupby sur tableau d'objets
 * @list Array
 * @keyGetter Function
 */
export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
};

export const dataURItoBlob = (dataURI)=> {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

};

/**
 *
 * @param elem html element
 * @returns {null|object}
 */
export const getHTMLElementDimensions = (elem)=> {
    let rect = null;
    if(elem) {
        rect = elem.getBoundingClientRect();
    }
  return rect;
};

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

(function () {
  window.currentMouseX = 0;
  window.currentMouseY = 0;

  // Guess the initial mouse position approximately if possible:
  var hoveredElement = document.querySelectorAll(':hover');
  hoveredElement = hoveredElement[hoveredElement.length - 1]; // Get the most specific hovered element

  if (hoveredElement != null) {
    var rect = hoveredElement.getBoundingClientRect();
    // Set the values from hovered element's position
    window.currentMouseX = window.scrollX + rect.x;
    window.currentMouseY = window.scrollY + rect.y;
  }

  // Listen for mouse movements to set the correct values
  window.addEventListener('mousemove', function (e) {
    window.currentMouseX = e.pageX;
    window.currentMouseY = e.pageY;
  }, /*useCapture=*/true);
}())

// Icons
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
Vue.use(VueLoaders);

// composant de notification
import Notifications from 'vue-notification'
Vue.use(Notifications);

// fullscreen
import fullscreen from 'vue-fullscreen'
Vue.use(fullscreen);

// Support de raccourcis clavier
import vueshortkey from 'vue-shortkey'
Vue.use(vueshortkey);



/**
 * Internationalisation
 */
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
Vue.use(VueI18Next);
// chargement des dictionnaires
import localesEN from './locales/en'
import localesFR from './locales/fr'

i18next.init({
  lng: 'fr',
  fallbackLng: 'en',
  resources: {
    en: { translation: localesEN },
    fr: { translation: localesFR },
  },
});
const i18n = new VueI18Next(i18next);

/**
 * To implement especially smartboard
 */
//import VueSignaturePad from 'vue-signature-pad';
//Vue.use(VueSignaturePad);

Vue.config.productionTip = false

//JSON Editor
import VJsoneditor from 'v-jsoneditor'
Vue.use(VJsoneditor);

new Vue({
  router,
  store,
  vuetify,
  i18n: i18n,
  render: h => h(App)
}).$mount('#app')

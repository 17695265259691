<style scoped>
  .main-content {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
</style>

<template>
  <div class="main-content">
    <!-- Cas d'une page Wiki -->
    <iframe v-if="viewNode && ['PAGE','WEBSITE','FILE'].indexOf(viewNode.class) >=0" :src="viewNode.url" width="100%" height="100%"></iframe>
    <!-- Cas des sites internet -->
    <v-jsoneditor v-else v-model="viewNode" :options="{ modes: ['view','text']}" :plus="false" height="100%"/>
  </div>
</template>

<script>

export default {
  name: 'simplexer-node-view',
  components: {  },
  props: {
    viewNode: {
      type: Object, default: function () { return null }
    },
  },
  data(){
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

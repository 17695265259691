<style scoped>
.main {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.content {
  height: calc(100vh - 80px);
  overflow: hidden;
}
</style>

<template>
  <div class="main">
    <context-menu :display="showContextMenu" ref="menu">
      <v-list dense>
        <v-list-item @click="showNode" ><i class="fa fa-eye"></i>&nbsp;Voir le noeud</v-list-item>
        <v-list-item @click="subgraphFromNode"><i class="fa fa-asterisk"></i>&nbsp;Ouvrir</v-list-item>
        <v-list-item @click="foldNodeNeighborhood"><i class="fa fa-compress"></i>&nbsp;Replier</v-list-item>
        <v-list-item @click="removeNode"><i class="fa fa-times"></i>&nbsp;Remove</v-list-item>
      </v-list>
    </context-menu>
    <Loader v-if="loading"></Loader>
    <AppBar>
      <!-- datasources dialog -->
      <DatasourcesDialog :datasources="datasources" :datasourceid="datasourceid" v-on:clickDatasource="changeDatasource"></DatasourcesDialog>
      <!-- nodes dialog -->
      <NodesDialog :nodes="allnodesInventory" v-on:clickNode="clickFromNodesDialog"></NodesDialog>
      <!-- Recherche ouverte  : pour le moment à partir d'un mot clé affiche le sous graphe associé en fonciton des résultat de recherche -->
      <OpenSearch v-on:submitSearch="submitSearch"></OpenSearch>
    </AppBar>
    <TopToolbar>
      <v-btn dark v-on:click="clearGraph">
        <i class="fas fa-eraser" title="clear" ></i>&nbsp;Purger
      </v-btn>
      <v-switch v-model="addToGraphFlag" :label="addToGraphFlag ? 'mode : ajouter' : 'mode : restart'"></v-switch>
      <v-spacer></v-spacer>
      <v-btn icon dark>
        <i :class="$t('Wysygraph_Toolbar_JsonData_icon')" :title="$t('Wysygraph_Toolbar_JsonData_title')" v-on:click="codeDialog = true"></i>
      </v-btn>
    </TopToolbar>
    <!-- dialog d'accès au json du graph -->
    <DataDialog :data="json"></DataDialog>
    <!-- node dialog -->
    <NodeDialog v-if="viewNode" :viewNode="viewNode"></NodeDialog>
    <div class="content">
      <Face2Face>
        <template v-slot:both-container-item> <i class="fas fa-asterisk" title="both"/> </template>
        <template v-slot:left-container-item> <i class="fas fa-table" title="datatable"/> </template>
        <template v-slot:right-container-item> <i class="fas fa-share-alt" title="network"/> </template>
        <template v-slot:leftSide>
          <GraphTable :incoming-nodes="nodes" :incoming-edges="edges" v-on:nodeClick="openContextMenu"></GraphTable>
        </template>
        <template v-slot:rightSide>
          <Visualgraph :incoming-nodes="nodes" :incoming-edges="edges" :update="flagUpdate" :menu="menu" ></Visualgraph>
        </template>
      </Face2Face>
    </div>
  </div>
</template>

<script>
import {isMobileOnly} from 'mobile-device-detect'

import { fetchDatasources , submitSearch , allNodes , subgraphFromNode } from '@/backend/mainAPI'
import { getGraphIndex , addToGraph , remodeNode , remodeEdge , foldNodeNeighbourhood } from '@/helpers/graphHandler'

import Loader from "@/components/common/Loader";
import Visualgraph from "@/components/viewers/visualgraph/Visualgraph";

import Face2Face from "@/components/common/positionning/Face2Face";
import DataDialog from "@/views/koman/parts/DataDialog";

import AppBar from "@/views/koman/parts/AppBar";
import TopToolbar from "@/components/common/TopToolbar";

import GraphTable from "@/views/koman/parts/GraphTable";
import NodeDialog from "@/views/koman/parts/NodeDialog";
import NodesDialog from "@/views/koman/parts/NodesDialog";
import DatasourcesDialog from "@/views/koman/parts/DatasourcesDialog";
import OpenSearch from "@/views/koman/parts/OpenSearch";

import ContextMenu from "@/components/common/ContextMenu";

export default {
  name: 'Koman',
  components: {
    GraphTable,Loader,Visualgraph,Face2Face,DataDialog,TopToolbar,NodeDialog,NodesDialog,DatasourcesDialog,OpenSearch , AppBar, ContextMenu
  },
  data: () => ({
    showContextMenu: false,
    addToGraphFlag: false,
    codeDialog: false,
    nodeDialog: false,
    viewNode: false,
    loading: false,
    options: {},
    search: '',
    allnodesInventory: [],
    nodes: [],
    edges: [],
    flagUpdate: 0,
    isMobile: false,
    graphIndex: null,
    datasources: null,
    datasourceid: null,
    datasourceType: null,
    currentNode: null
  }),
  computed: {
    json: function(){
      return { nodes: this.nodes, edges: this.edges }
    },
    menu: function(){
      return [
        {
          content: '<i class="fa fa-eye"></i>',
          select: (ele)=>{
            this.viewNode = ele.data();
            this.showNode();
          }
        },
        {
          content: '<i class="fa fa-asterisk"></i>',
          select: (ele)=>{
            //@todo à améliorer : les virtuals nodes ne peuvent être expand
            if(ele.data().virtualNode && ele.data().virtualNode === true){
              this.$notify({ group: 'foo', type: 'warning', title: ele.data().class, text: 'noeud virtuel'})
            }else{
              this.viewNode = ele.data();
              this.subgraphFromNode()
            }
          }
        },
        {
          content: '<i class="fa fa-compress"></i>',
          select: (ele)=>{
            //@todo à améliorer : les virtuals nodes ne peuvent être réduits
            if(ele.data().virtualNode && ele.data().virtualNode === true){
              this.$notify({ group: 'foo', type: 'warning', title: ele.data().class, text: 'noeud virtuel'})
            }else{
              this.viewNode = ele.data();
              this.foldNodeNeighborhood()
            }
          }
        },
        {
          content: '<i class="fa fa-times"></i>',
          select: (ele)=>{
            this.viewNode = ele.data();
            this.removeNode()
          }
        }
      ]
    }
  },
  watch: {
    edges(){ this.buildGraphIndex(); },
    datasourceid(){
      this.clearGraph();
      this.allnodes();
    }
  },
  mounted() {
    this.isMobile = isMobileOnly;
    if(this.isMobile) this.showFaceLeft = false;

    //@todo test
    fetchDatasources(this.$http,(datasources)=>{this.datasources = datasources});
  },
  methods: {
    changeDatasource(datasource){
      this.datasourceid = datasource.id;
      this.datasourceType = datasource.type;
    },
    renderGraph(nodes,edges){
      this.edges = edges; this.nodes = nodes; this.flagUpdate = Date.now();
    },
    buildGraphIndex(){
      this.graphIndex = getGraphIndex(this.nodes,this.edges);
    },
    clickFromNodesDialog(node){
      this.viewNode = node.data;
      this.subgraphFromNode()
    },
    openContextMenu(node) {
      this.viewNode = node.data;
      this.$refs.menu.open();
    },
    removeNode(){
      this.$refs.menu.close();
      let newGraph = remodeNode(this.nodes,this.edges,this.viewNode.id);
      this.renderGraph(newGraph.nodes,newGraph.edges);
    },
    foldNodeNeighborhood(){
      let newgraph = foldNodeNeighbourhood(this.nodes,this.edges,this.graphIndex,this.viewNode.id);
      this.renderGraph(newgraph.nodes,newgraph.edges);
    },
    addToGraph(nodes,edges){
      let newgraph  = addToGraph(this.nodes,this.edges, nodes,edges);
      this.renderGraph(newgraph.nodes,newgraph.edges)
    },
    clearGraph(){
      this.renderGraph([],[])
    },
    showNode(){
      this.$refs.menu.close();
      this.nodeDialog = true;
    },
    submitSearch(search){
      this.loading =true;
      submitSearch(this.$http,this.datasourceid,search,(data)=>{
        if(this.addToGraphFlag) this.addToGraph(data.nodes,data.relationships); else this.renderGraph(data.nodes,data.relationships);
        this.loading =false;
      });
    },
    allnodes(){
      this.loading =true;
      allNodes(this.$http,this.datasourceid, (data)=>{ this.allnodesInventory = data.nodes; this.loading = false; });
    },
    subgraphFromNode(){
      //@todo : pas très elegant comme solution
      let filter = this.datasourceType === 'mediawiki' ? this.viewNode.label : this.viewNode.id;
      this.loading =true;
      subgraphFromNode(this.$http,this.datasourceid,filter,(graph)=>{
        if(this.addToGraphFlag) this.addToGraph(graph.nodes,graph.relationships); else  this.renderGraph(graph.nodes,graph.relationships);
        this.loading =false;
      });
    }
  }
}
</script>

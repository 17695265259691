<!-- -->
<style scoped>
    .loader-container {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: lightgrey;
        opacity: 0.5;
    }
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
</style>

<template>
    <div class="loader-container">
        <div class="loader">
            <vue-loaders name="ball-scale-ripple-multiple" color="grey" scale="1"></vue-loaders>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loader',
        components: { },
        data(){
            return{
            }
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

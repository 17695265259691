<style scoped>
.main-content {
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<template>
  <v-dialog
      v-model="dialog"
      max-width="90%"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" :icon="isMobile ? true : false" :plain="!isMobile ? true : false" >
        <i class="fas fa-search"></i> {{ !isMobile ? "Recherche" : ""}}
      </v-btn>
    </template>
    <v-card>
      <v-card-title >
        Recherche Ouverte
      </v-card-title>
      <v-card-text>
        <v-text-field solo v-model="search" light dense hide-details v-on:keyup.enter="submitSearch" >
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-on:click="submitSearch">
          <i class="fas fa-search"></i> Search
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {isMobileOnly} from "mobile-device-detect";

export default {
  name: 'koman-open-search',
  components: {  },
  props: {
  },
  data(){
    return {
      dialog: false,
      search: '',
      isMobile: isMobileOnly
    }
  },
  mounted() {
  },
  methods: {
    submitSearch(){
      this.dialog = false;
      this.$emit("submitSearch",this.search);
    },
    clickSearchResult(searchItem){
      this.dialog = false;
      this.$emit("clickSearchItem",searchItem);
    }
  }
}
</script>

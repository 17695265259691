import { render, staticRenderFns } from "./Koman.vue?vue&type=template&id=7e33ef05&scoped=true&"
import script from "./Koman.vue?vue&type=script&lang=js&"
export * from "./Koman.vue?vue&type=script&lang=js&"
import style0 from "./Koman.vue?vue&type=style&index=0&id=7e33ef05&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e33ef05",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VList,VListItem,VSpacer,VSwitch})

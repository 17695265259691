<style>
  .visualgraph {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: floralwhite;
    border: solid thin lightgrey;
  }
  .cytoscape {
    width: 100%;
    height: calc(100% - 32px);
    margin: 0;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }
</style>

<template>
  <div class="visualgraph">
    <v-chip-group class="settings">
      <!--v-chip v-if="!viewerOnly" small color="blue" :title="$t('Visualgraph_BottomToolbar_EditModes_label')" v-on:click="editModeSheet = true">
        <i :class="selectedEditMode === 'draw' ? $t('Visualgraph_DrawEditMode_icon') : $t('Visualgraph_EdgeEditMode_icon')"></i>&nbsp;{{selectedEditMode === 'draw' ? $t('Visualgraph_DrawEditMode_label') : $t('Visualgraph_EdgeEditMode_label')}}
      </v-chip-->
      <v-chip small color="pink" :title="$t('Visualgraph_BottomToolbar_Layouts_label')" v-on:click="layoutSheet = true">
        <i :class="$t('Visualgraph_BottomToolbar_Layouts_icon')"></i>&nbsp;{{layoutMode}}
      </v-chip>
      <v-chip small color="orange" :title="$t('Visualgraph_BottomToolbar_ShowClusters_label')" v-on:click="showClusters = !showClusters">
        <i :class="$t('Visualgraph_ShowClusters_icon')"></i>&nbsp;{{showClusters ? $t('Visualgraph_ShowClustersEnable_label') : $t('Visualgraph_ShowClustersDisable_label')}}
      </v-chip>
      <v-chip small  :title="$t('Visualgraph_Fit_label')" v-on:click="fitGraph" color="green">
        <i :class="$t('Visualgraph_Fit_icon')"/> {{ $t('Visualgraph_Fit_label') }}
      </v-chip>
      <v-chip small  :title="$t('Visualgraph_Capture_label')" v-on:click="captureGraph" color="blue">
        <i :class="$t('Visualgraph_Capture_icon')"></i> {{$t('Visualgraph_Capture_label')}}
      </v-chip>
    </v-chip-group>
    <v-bottom-sheet v-model="layoutSheet">
      <v-list>
        <v-subheader>{{$t('Visualgraph_LayoutsSheet_Header_label')}}</v-subheader>
        <v-list-item v-for="aLayout in layoutList" @click="changeLayout(aLayout)" :key="aLayout">{{aLayout}}</v-list-item>
      </v-list>
    </v-bottom-sheet>
    <div class="cytoscape" ref="cytoscape">
    </div>
  </div>
</template>

<script>
  import cytoscape from 'cytoscape';
  import cxtmenu from 'cytoscape-cxtmenu';
  cytoscape.use( cxtmenu );
  import dagre from 'cytoscape-dagre';
  cytoscape.use( dagre );
  import cola from 'cytoscape-cola';
  cytoscape.use( cola );

  var nodeHtmlLabel = require('cytoscape-node-html-label');
  nodeHtmlLabel( cytoscape ); // register extension

  import randomColor from 'randomcolor'

  var FileSaver = require('file-saver');

  //variable permettant de stocker l'instance cytoscape : ne fonctionne pas si mise dans data (je ne sais pas pourquoi)
  let cy = null;

  window.mycy = cy;

  import { layouts } from './config/layouts'
  import { styles } from './config/styles'
  import { radialConfig } from './config/radialMenu'

  import {dataURItoBlob} from '@/helpers'


  var debug = false;
  /**
   *  Visualiseur de réseaux
   *  @displayName Visualgraph
   */
  export default {
    name: 'visualgraph',
    components: {  },
    props: {
      /**
       * Noeuds du graphe dans le format cytoscape.js
       */
      incomingNodes: { type: Array, default: function(){ return []}},
      /**
       * Relations du graphe dans le format cytoscape.js
       */
      incomingEdges: { type: Array, default: function(){ return []}},
      /**
       *  Clusters / Groupes du graphe dans le format de noeuds cytoscape.js
       */
      incomingClusters: { type: Array, default: function(){ return []}},
      /**
       * Drapeau sous la forme d'un nombre qui lorsque la valeur change déclenche un nouveau rendu du graphe
       */
      update: { type: Number , default: function() { return null }},
      /**
       * Entrées du menu contextuel du graphe
       */
      menu: { type: Array, default: function() { return []; }},
      /**
       * Légende du graphe
       */
      incomingLegend: { type: Array, default: function() { return []; }}
    },
    data(){
      return {
        layoutSheet: false, // show layout choice menu
        layoutMode: 'cola',// layout algorithm
        editModeSheet: false, // show edition menu
        generalSheet: false, // show general menu of graphic view
        showClusters: true,
        mycy: null,
        showLegend: false,
        enableClustering: true
      }
    },
    computed: {
      legend(){
        return this.incomingLegend //legend(this.incomingLegend)
      },
      layoutList(){
        return Object.keys(layouts);
      }
    },
    created() {
    },
    mounted() {
      this.renderGraph()
    },
    watch: {
      update(newVal, oldVal){
        if(newVal !== oldVal){
          //@todo plutôt de re créer le graph faire des modifs en diff
          this.renderGraph()
        }
      },
      enableClustering(newVal,oldVal){
        if(newVal !== oldVal) this.renderGraph()
      }
    },
    methods: {
      toggleLegend(){
        this.showLegend = !this.showLegend;
      },
      changeLayout(type = 'random'){
        this.layoutMode = type;
        let layout = cy.layout(layouts[this.layoutMode])
        layout.run()
        this.layoutSheet = false;
      },
      fitGraph(){
        cy.fit();
      },
      captureGraph(){
        let blob = dataURItoBlob(cy.jpg());
        FileSaver.saveAs(blob, "capture.jpg");
      },
      layout(type = 'random'){
        this.layoutMode = type;
        let layout = cy.layout(layouts[this.layoutMode])
        layout.run()
        this.layoutSheet = false;
      },
      visualSemantic(elements){
        let uniqNodeClasses = elements.map((element)=>{ return element.data.class}).filter((v, i, a) => a.indexOf(v) === i)
        let colors = randomColor({count: uniqNodeClasses.length, luminosity: "dark"});
        let legend = {};
        uniqNodeClasses.forEach((myClass,index)=>{
          legend[myClass] = colors[index]
        });
        elements.forEach((element)=>{
          element.data.color = legend[element.data.class]
        })
      },
      renderGraph(){

        let nodes = this.incomingNodes.filter((node)=>{ return node.id && node.id !== ''});
        let edges = this.incomingEdges.filter((edge)=>{return edge.data.id && edge.data.id !== '' && edge.data.source && edge.data.source !== '' && edge.data.target && edge.data.target !== ''});
        let clusters = []//JSON.parse(JSON.stringify(this.incomingClusters.filter((element)=>{ return element.data.id && element.data.id !== ''})));
        clusters.forEach((cluster)=>{
          cluster.data.cluster = true
        });
        this.visualSemantic(nodes);
        this.visualSemantic(edges);

        let elements = {
          nodes: /*this.enableClustering ? clusters.concat(nodes) :*/ nodes,
          edges: edges
        };
        // désactivation de l'ensemble des warning en console
        cytoscape.warnings(false);
        let cyInstance = cytoscape({
          boxSelectionEnabled: false,
          headless: false,
          elements: elements,
          container: this.$refs.cytoscape,
          style: styles,
          layout: layouts[this.layoutMode]
        });
        //https://github.com/kaluginserg/cytoscape-node-html-label
        /*
        cyInstance.nodeHtmlLabel([
          {
            query: 'node', // cytoscape query selector
            halign: 'center', // title vertical position. Can be 'left',''center, 'right'
            valign: 'center', // title vertical position. Can be 'top',''center, 'bottom'
            halignBox: 'center', // title vertical position. Can be 'left',''center, 'right'
            valignBox: 'center', // title relative box vertical position. Can be 'top',''center, 'bottom'
            cssClass: '', // any classes will be as attribute of <div> container for every title
            tpl(data) {
              return '<span><i class="fa fa-eye-slash"></i> ' + data + '</span>'; // your html template here
            }
          }
        ]);
*/
        // ajout menu contexte radial
        let radialMenuConfig = radialConfig(this.menu);
        let menu = cyInstance.cxtmenu( radialMenuConfig );

        // ajout interaction sur le noeud
        let current = this;
        cyInstance.on('mouseover', 'node', function(evt){
          let node = evt.target;
          current.$emit("overNode",evt,this.id());
        });
        cyInstance.on('mouseover', 'edge', function(evt){
          let edge = evt.target;
          current.$emit("overEdge",evt,this.id());
        });
        cyInstance.on('mouseout', 'node', function(evt){
          let node = evt.target;
          current.$emit("leaveNode",evt,this.id());
        });
        cy = cyInstance;
        window.mycy = cy;
      }
    }
  }
</script>

<style scoped>
  .main {
    height: 100%;
  }
  .tab-content{
    height: calc(100vh - 128px);
    overflow: hidden;
  }
</style>

<template>
  <v-card class="main" >
    <v-tabs background-color="transparent" grow v-model="tab" >
      <v-tab key="nodes">Nodes</v-tab>
      <v-tab key="edges">Edges</v-tab>
      <!--v-tab key="cluster">Clusters</v-tab-->
    </v-tabs>
    <v-tabs-items class="tab-content" v-model="tab" ref="graphTables">
      <v-tab-item  key="nodes"  >
        <v-card>
          <v-card-title>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="nodeHeaders"
              :items="incomingNodes"
              :search="search"
              item-key="data.id"
              show-expand
              class="elevation-1"
              dense
              :height="tableHeight"
              fixed-header
              @click:row="handleNodeLineClick"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 10px">
                <v-card>
                  <v-simple-table dense >
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Value
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(value,property) in item.data.properties" :key="property">
                        <td>{{ property }}</td>
                        <td>{{ value }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </td>
            </template>
          </v-data-table>

        </v-card>
      </v-tab-item>
      <v-tab-item  key="edges">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="searchEdge"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                outlined
                dense
            ></v-text-field>
          </v-card-title>
          <v-data-table :headers="edgeHeaders" :items="incomingEdges" :search="searchEdge" item-key="data.id" show-expand class="elevation-1" :height="tableHeight"
              dense
              fixed-header
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              @click:row="handleEdgeLineClick"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card>
                  <v-simple-table dense v-if="item.data.properties" >
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">
                          Name
                        </th>
                        <th class="text-left">
                          Value
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(value,property) in item.data.properties" :key="property">
                        <td>{{ property }}</td>
                        <td>{{ value }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

export default {
  name: 'graph-table',
  components: { },
  props: {
    /**
     * Noeuds du graphe dans le format cytoscape.js
     */
    incomingNodes: {
      type: Array, default: function () {
        return []
      }
    },
    /**
     * Relations du graphe dans le format cytoscape.js
     */
    incomingEdges: {
      type: Array, default: function () {
        return []
      }
    },
    /**
     *  Clusters / Groupes du graphe dans le format de noeuds cytoscape.js
     */
    incomingClusters: {
      type: Array, default: function () {
        return []
      }
    }
  },
  data(){
    return {
      showContextMenu: false,
      tab: null,
      expanded: [],
      singleExpand: false,
      nodeHeaders: [
        /*{ text: 'id', value: 'data.id', },*/
        { text: 'label', value: 'data.label' },
        { text: 'class', value: 'data.class' },
      ],
      edgeHeaders: [
        /*{ text: 'id', value: 'data.id', },*/
        { text: 'source', value: 'data.source' },
        { text: 'type', value: 'type' },
        { text: 'target', value: 'data.target' },
      ],
      searchEdge: "",
      search: "",
      tableHeight: null,
      currentNode: null,
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.tableHeight = this.$refs.graphTables.$el.clientHeight - 80;
  },
  watch: {
  },
  methods: {
    handleNodeLineClick(value){
      this.$emit("nodeClick",value)
      this.currentNode = value;
    },
    handleEdgeLineClick(value){
      this.$emit("edgeClick",value)
      this.currentNode = value;
    }
  }
}
</script>


export const getGraphIndex = (nodes,edges)=>{
    let graphIndex = {
        nodes: {},
        edges: {}
    };

    nodes.forEach((node)=>{
        graphIndex.nodes[node.data.id.toString()] = {
            in: [],
            out: [],
            all: []
        }
    })

    edges.forEach((edge)=>{
        graphIndex.nodes[edge.data.source].out.push(edge.data.id);
        graphIndex.nodes[edge.data.target].in.push(edge.data.id);
        graphIndex.nodes[edge.data.target].all.push(edge.data.id);
        graphIndex.nodes[edge.data.source].all.push(edge.data.id);
    });

    return graphIndex;
};

/**
 *
 * @param currentNodes
 * @param currentEdges
 * @param newNodes
 * @param newEdges
 * @returns {{nodes: *, edges: *}}
 */
export const addToGraph = (currentNodes,currentEdges,newNodes,newEdges) => {

    // tableaux des identifiants des noeuds et relations déjà dans le graphe actuel
    let currentNodeIds = currentNodes.map((node)=>{ return node.data.id });
    let currentEdgeIds = currentEdges.map((edge)=>{ return edge.data.id});

    // Détection du diff entre le graphe actuel et le sous graphe reçu
    let actualNewNodes = newNodes.filter((node)=>{
        return currentNodeIds.indexOf(node.data.id) < 0
    });

    let actualNewEdges = newEdges.filter((edge)=>{
        return currentEdgeIds.indexOf(edge.data.id) < 0
    });
    let newGraph = {
        nodes: actualNewNodes.concat(currentNodes),
        edges: actualNewEdges.concat(currentEdges)
    };
    return newGraph;
};

export const remodeNode = (currentNodes,currentEdges,nodeId)=>{
    let filterNodes = currentNodes.filter((node)=>{ return node.data.id.toString() !== nodeId.toString() });
    let filterEdges = currentEdges.filter((edge)=>{ return edge.data.source.toString() !== nodeId && edge.data.target.toString() !== nodeId.toString() });
    return {
        nodes: filterNodes,
        edges: filterEdges
    }
}

export const foldNodeNeighbourhood = (currentNodes,currentEdges,graphIndex,id)=>{
    let edgesFromNode = currentEdges.filter((edge)=>{ return edge.data.source.toString() === id || edge.data.target.toString() === id});

    let candidateNodeIdsToDelete = [];

    let edgesFromNodeIds = [];

    edgesFromNode.forEach((edge)=>{
        let nodeId = edge.data.source === id ? edge.data.target : edge.data.source;
        if(graphIndex.nodes[nodeId].all.length === 1) {
            candidateNodeIdsToDelete.push(nodeId);
            //Solution pour supprimer pour supprimer uniquement les arcs des noeuds isolés
            edgesFromNodeIds.push(edge.data.id);
        }else{
        }
    })
    //Solution pour supprimer pour fermer tous les arcs des noeuds
    //let edgesFromNodeIds = edgesFromNode.map((edge)=>{return edge.data.id});

    return {
        nodes: currentNodes.filter((node)=>{ return candidateNodeIdsToDelete.indexOf(node.data.id) < 0}),
        edges : currentEdges.filter((edge)=>{ return edgesFromNodeIds.indexOf(edge.data.id) < 0})
    }
}

export const remodeEdge = ()=>{

}

<style scoped>
.main {
  width: 100%;
  height: calc(100vh - 80px);
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>

<template>
  <div>
    <AppBar></AppBar>
    <TopToolbar></TopToolbar>
    <Face2Face>
      <template v-slot:both-container-item> <i class="fas fa-asterisk" title="both"/> </template>
      <template v-slot:left-container-item> <i class="fas fa-clock" title="timeline"/> </template>
      <template v-slot:right-container-item> <i class="fas fa-table" title="datatable"/> </template>
      <template v-slot:leftSide>
        <VisualTimeline></VisualTimeline>
      </template>
      <template v-slot:rightSide>
      </template>
    </Face2Face>
  </div>
</template>

<script>

import AppBar from "@/views/koman/parts/AppBar";
import TopToolbar from "@/components/common/TopToolbar";
import Face2Face from "@/components/common/positionning/Face2Face";

import VisualTimeline from "@/components/viewers/visualtimeline/Visualtimeline";

export default {
  name: 'simplex-chronoscope',
  components: {
    AppBar, TopToolbar, Face2Face, VisualTimeline
  },
  data: () => ({
  }),
  watch: {
  },
  mounted() {
  },
  methods: {
  }

}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'

import Koman from '@/views/koman/Koman'
import Home from "@/views/Home";
import Chronoscope from "@/views/koman/Chronoscope";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Default',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/koman',
    name: 'Koman',
    component: Koman
  },
  {
    path: '/chrono',
    name: 'Chrono',
    component: Chronoscope
  }
]

const router = new VueRouter({
  routes
})

export default router

<style scoped>
.main-content {
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<template>
  <v-dialog
      v-model="dialog"
      width="70%"
      :persistent="datasourceid ? false : true"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" :icon="isMobile ? true : false" :plain="!isMobile ? true : false" >
        &nbsp;<i class="fa fa-database"></i> <span v-if="!isMobile">{{ datasourceid }}</span>
      </v-btn>
    </template>
    <v-flex class="text-center" v-if="datasources === null">
      <v-progress-linear></v-progress-linear>
    </v-flex>
    <v-card outlined class="main-content" v-else>
      <v-card-title>
        Datasources
        <v-spacer></v-spacer>
        <v-chip color="orange" outlined>
          Selected : {{datasourceid}}
        </v-chip>
      </v-card-title>
      <v-card-text class="main-content">
        <v-list>
          <v-list-item v-for="datasource in datasources" v-on:click="clickDatasource(datasource)">
            <v-list-item-content>
              <v-list-item-title>
                {{datasource.name}}
              </v-list-item-title>
              <v-list-item-subtitle>
                ID : {{datasource.id}} - TYPE : {{datasource.type}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="datasourceid" >
        <v-spacer></v-spacer>
        <v-btn plain v-on:click="dialog = false">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {isMobileOnly} from 'mobile-device-detect'
import Loader from "@/components/common/Loader";

export default {
  name: 'koman-datasources-dialog',
  components: { Loader },
  props: {
    datasources: {
      type: Array, default: function () { return [] }
    },
    datasourceid: {
      type: String, default: function () { return null }
    }
  },
  data(){
    return {
      dialog: false,
      isMobile : false
    }
  },
  mounted() {
    this.isMobile = isMobileOnly;
    if(!this.datasourceid) this.dialog =true;
  },
  methods: {
    clickDatasource(datasource){
      this.dialog = false;
      this.$emit("clickDatasource",datasource);
    }
  }
}
</script>

<!-- This component split the screen in two part. It is possible show left, right or boths parts (ie containers). On mobile device only left or right container -->
<style scoped>
    .fullspace {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }
    .main {
        height: 100%;
        padding: 0;
        margin: 0;
    }
    .face {
        height: calc(100vh - 80px);
        padding: 0;
        margin: 0;
    }
    .toolbarTest {
        position: absolute;
        z-index: 2;
        right: 5px;
        bottom: 70px;
    }
    .outerContainer .innerContainer {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        color: white;
    }
</style>
<template>
    <v-container class="fullspace" fluid no-gutters>
        <!--v-bottom-sheet v-model="facesSheet">
            <v-list>
                <v-subheader>{{$t('Face2Face_OptionsSheet_Header_label')}}</v-subheader>
                <v-list-item v-if="!isMobile" v-on:click="activeBoth" v-shortkey="['ctrl', 'alt', 'b']" @shortkey="activeBoth" >
                    <slot name="both-container-item"></slot>
                </v-list-item>
                <v-list-item v-on:click="activeFaceLeft" v-shortkey="['ctrl', 'alt', 'l']" @shortkey="activeFaceLeft">
                    <slot name="left-container-item"></slot>
                </v-list-item>
                <v-list-item  v-on:click="activeFaceRight" v-shortkey="['ctrl', 'alt', 'r']" @shortkey="activeFaceRight" >
                    <slot name="right-container-item"></slot>
                </v-list-item>
            </v-list>
        </v-bottom-sheet-->
        <v-row class="main">
            <div class="toolbarTest">
              <v-speed-dial v-model="fab" :bottom="true" :right="true" :open-on-hover="false"
              >
                <template v-slot:activator>
                  <v-btn v-model="fab" color="green" dark fab >
                    <v-icon v-if="fab">
                      mdi-close
                    </v-icon>
                    <i :class="$t('Face2Face_button_icon')" v-else></i>
                  </v-btn>
                </template>
                <v-btn fab dark small color="green"
                    v-if="!isMobile" v-on:click="activeBoth" v-shortkey="['ctrl', 'alt', 'b']" @shortkey="activeBoth"
                >
                  <slot name="both-container-item"></slot>
                </v-btn>
                <v-btn fab dark small color="indigo"
                    v-on:click="activeFaceLeft" v-shortkey="['ctrl', 'alt', 'l']" @shortkey="activeFaceLeft"
                >
                  <slot name="left-container-item"></slot>
                </v-btn>
                <v-btn fab dark small color="red"
                    v-on:click="activeFaceRight" v-shortkey="['ctrl', 'alt', 'r']" @shortkey="activeFaceRight"
                >
                  <slot name="right-container-item"></slot>
                </v-btn>
              </v-speed-dial>
              <!--v-btn class="mx-2" fab dark small color="success" v-on:click="showFaceSheet" :title="$t('Face2Face_button_title')">
                    <i :class="$t('Face2Face_button_icon')"></i>
                </v-btn-->
            </div>
            <v-col class="face" :cols="showFaceRight ? 6 : 12" ref="leftSide" v-if="showFaceLeft" height="100%">
                <slot name="leftSide"></slot>
            </v-col>
            <v-col class="face" :cols="showFaceLeft ? 6 : 12" ref="rightSide" v-if="showFaceRight" height="100%">
                <slot name="rightSide"></slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {isMobileOnly} from 'mobile-device-detect'
    export default {
        name: 'face2face',
        components: {
        },
        props: {
        },
        data(){
            return {
                facesSheet: false,
                showFaceLeft: true,
                showFaceRight: true,
                isMobile: false,
                fab: false
            }
        },
        mounted(){
            this.isMobile = isMobileOnly;
            if(this.isMobile) this.showFaceLeft = false;
        },
        methods: {
            showFaceSheet(){
              this.facesSheet = true;
            },
            activeBoth(){
                this.showFaceLeft = true;
                this.showFaceRight = true;
                this.facesSheet = false;
            },
            activeFaceLeft(){
                this.showFaceLeft = true;
                this.showFaceRight = false;
                this.facesSheet = false;
            },
            activeFaceRight(){
                this.showFaceRight = true;
                this.showFaceLeft = false;
                this.facesSheet = false;
            }
        }
    }
</script>


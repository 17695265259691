<style>
</style>

<template>
    <div>
        <v-dialog v-model="$parent.codeDialog"  hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="secondary" dense>
                    <v-btn icon dark @click="data = false">
                        <i :class="$t('Wysygraph_CodeDialog_Header_icon')"></i>
                    </v-btn>
                    <v-toolbar-title>
                        {{$t('Wysygraph_CodeDialog_Header_label')}}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$parent.codeDialog = false" :title="$t('Wysygraph_CodeDialog_Header_CloseButton_label')">
                        <i :class="$t('Wysygraph_CodeDialog_Header_CloseButton_icon')"></i>
                    </v-btn>
                </v-toolbar>
                <v-jsoneditor v-model="data" :options="{ modes: ['view','text']}" :plus="false" height="60vh"/>
            </v-card>
        </v-dialog>
        <v-bottom-sheet v-model="exportSheet">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="orange" @click="exportSheet = false">
                    {{$t('Wysygraph_ExportSheet_Cancel_label')}}
                </v-btn>
                <v-btn class="mt-6" text color="green" @click="exportDatas">
                    {{$t('Wysygraph_ExportSheet_Download_label')}}
                </v-btn>
                <div class="py-3">
                    <v-text-field placeholder="file name" v-model="exportFileName"></v-text-field>
                </div>
            </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="importSheet">
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="orange" @click="importSheet = false">
                    {{$t('Wysygraph_ImportSheet_Cancel_label')}}
                </v-btn>
                <v-btn class="mt-6" text color="green" @click="importDatas">
                    {{$t('Wysygraph_ImportSheet_Upload_label')}}
                </v-btn>
                <div class="py-3">
                    <v-file-input filled v-model="importFile" accept=".json,application/json" placeholder="choiceFile"></v-file-input>
                </div>
            </v-sheet>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="$parent.exportAsSheet">
            <v-list>
                <v-subheader>{{$t('Wysygraph_ExportSheet_Header_label')}}</v-subheader>
                <v-list-item @click="exportAs('cql')">
                    <v-list-item-icon>
                        <i class="fa fa-file"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{$t('Wysygraph_ExportSheet_CypherMode_label')}}
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="exportAs('csv')">
                    <v-list-item-icon>
                        <i class="fa fa-file-csv"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{$t('Wysygraph_ExportSheet_CsvMode_label')}}
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="exportAs('xml')">
                    <v-list-item-icon>
                        <i class="fa fa-file-code"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{$t('Wysygraph_ExportSheet_XMLMode_label')}}
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="exportAs('json')">
                    <v-list-item-icon>
                        <i class="fab fa-js"></i>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{$t('Wysygraph_ExportSheet_JsonMode_label')}}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import { saveAs } from 'file-saver';
    import convert from 'xml-js'
    import Papa from 'papaparse'
    import JSZip from 'jszip'

    export default {
        name: 'wysygraph-data-dialog',
        components: {},
        props: {
          data: {
            type: Object, default: function () { return null }
          }
        },
        data(){
            return {
                importSheet: false,
                exportSheet: false,
                importFile: null,
                exportFileName: "export",
                exportType: "json"
            }
        },
        methods: {
            exportAs(type){
                switch(type){
                    case "json":
                        this.exportType = "json";
                        this.$parent.exportAsSheet = false;
                        this.exportSheet = true;
                        break;
                    case "xml":
                        this.exportType = "xml";
                        this.$parent.exportAsSheet = false;
                        this.exportSheet = true;
                        break;
                    case 'cql':
                        this.exportType = "cql";
                        this.$parent.exportAsSheet = false;
                        this.exportSheet = true;
                        break;
                    case "csv":
                        let zip = new JSZip();
                        let nodes = this.$parent.graph.nodes.map((element)=>{
                            return element.data
                        });
                        let edges = this.$parent.graph.edges.map((element)=>{
                            return element.data
                        });
                        let clusters = this.$parent.graph.clusters.map((element)=>{
                            return element.data
                        });
                        zip.file("nodes.csv", Papa.unparse(nodes));
                        zip.file("clusters.csv", Papa.unparse(clusters));
                        zip.file("edges.csv", Papa.unparse(edges));
                        zip.generateAsync({type:"blob"})
                            .then(function(content) {
                                // see FileSaver.js
                                saveAs(content, "export.zip");
                            });
                        break;
                    default:
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            title: "export "+type,
                            text: "sorry not ready but comming soon"
                        });
                }
            },
            exportDatas(){
                let admimesExport = { model: [], rendering: [], graph: this.$parent.graph };
                let blob;
                switch(this.exportType){
                    case "json":
                        blob = new Blob([JSON.stringify(admimesExport)], {type: "application/json;charset=utf-8"});
                        break;
                    case "xml":
                        let options = {compact: true, ignoreComment: true, spaces: 4};
                        let result = convert.json2xml(admimesExport, options);
                        blob = new Blob([result], {type: "application/xml;charset=utf-8"});
                        break;
                    case "cql":
                        let cql = graph2cql(this.$parent.graph);
                        blob = new Blob([cql], {type: "application/xml;charset=utf-8"});
                        break;
                    default:
                }
                saveAs(blob, this.exportFileName ? this.exportFileName+"."+this.exportType : "admimes-export."+this.exportType);
                this.exportSheet = false;
            },
            importDatas(){
                let reader = new FileReader();
                reader.readAsText(this.importFile);
                reader.onload = () => {
                    let jsonString = reader.result;
                    let jsonData = JSON.parse(jsonString);
                    this.$parent.clusters = jsonData.graph.clusters;
                    this.$parent.nodes = jsonData.graph.nodes;
                    this.$parent.edges = jsonData.graph.edges;
                    this.$parent.update2All();
                    this.importSheet = false;
                }

            }
        }
    }
</script>

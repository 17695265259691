<!--  -->
<style scoped>
    .sidebar {
        z-index: 20000 !important;
    }
</style>

<template>
    <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            class="sidebar"
    >
        <v-list-item>
            <v-list-item-avatar>
                <img :src="$t('Application_Sidebar_Icon')" class="imageApp" v-on:click="sidebar = Date.now()">
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title>{{$t("Application_Navbar_Title")}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list dense>
            <v-subheader>{{$t('Application_Sidebar_header_Sitemap_label')}}</v-subheader>
            <v-list-item link :title="$t('Application_Navbar_Entry_Home_title')" to="/home">
                <v-list-item-icon >
                    <i :class="$t('Application_Navbar_Entry_Home_Icon')"></i>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{$t('Application_Navbar_Entry_Home_title')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/koman" :title="$t('Application_Navbar_Entry_Koman_title')">
                <v-list-item-icon >
                    <i :class="$t('Application_Navbar_Entry_Koman_Icon')"></i>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{$t('Application_Navbar_Entry_Koman_title')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <slot></slot>
        <v-divider></v-divider>
        <v-list dense>
            <v-subheader>{{$t('Application_Sidebar_header_Settings_label')}}</v-subheader>
            <v-list-item @click="languagesSheet = !languagesSheet" :title="$t('Application_Navbar_Entry_language_title')">
                <v-list-item-icon>
                    <i :class="$t('Application_Navbar_Entry_Langauge_Icon')"></i>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('Application_Navbar_Entry_language_title')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="()=>{ drawer = false; dialogHelp = true; }" :title="$t('Application_Navbar_Entry_Help_title')">
                <v-list-item-icon>
                    <i :class="$t('Application_Navbar_Entry_Help_Icon')"></i>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{$t('Application_Navbar_Entry_Help_title')}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-bottom-sheet v-model="languagesSheet">
            <v-list>
                <v-subheader>{{$t('Application_LanguageSheet_Header_Label')}}</v-subheader>
                <v-list-item @click="changeLanguage('fr')">{{$t('Application_LanguageSheet_French_Label')}}</v-list-item>
                <v-list-item @click="changeLanguage('en')">{{$t('Application_LanguageSheet_English_Label')}}</v-list-item>
            </v-list>
        </v-bottom-sheet>
        <v-dialog v-model="dialogHelp"  transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="orange">
                    <v-btn icon dark @click="dialog = false">
                        <i :class="$t('help_dialog_close_icon')"></i>
                    </v-btn>
                    <v-toolbar-title>{{$t("help_dialog_header_title")}}</v-toolbar-title>
                </v-toolbar>
                help
            </v-card>
        </v-dialog>

    </v-navigation-drawer>
</template>

<script>

    export default {
        name: 'sidebar',
        components: {},
        props: {
          openTrigger: { type: Number, default: function(){ return null}},
          closeTrigger: { type: Number, default: function(){ return null}}
        },
        created: function(){
        },
        mounted: function(){
        },
        data: function() {
            return {
                drawer: false,
                languagesSheet: false,
                dialogHelp: false
            };
        },
        watch: {
            openTrigger(newVal,oldVal){
                if(newVal && newVal !== oldVal) this.drawer = true
            },
            closeTrigger(newVal,oldVal){
                if(newVal && newVal !== oldVal) this.drawer = false
            }
        },
        methods: {
            changeLanguage(codeLang){
                this.$i18n.i18next.changeLanguage(codeLang);
                this.languagesSheet = false;
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: this.$t('Application_notification_language_header'),
                    text: this.$t(codeLang)
                });
            }
        }
    }
</script>


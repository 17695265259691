<style scoped>
.main-content {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}
</style>

<template>
  <!-- node dialog -->
  <v-dialog
      v-model="$parent.nodeDialog"
      width="100%"
      scrollable
      :fullscreen="fullscreen"
      v-if="$parent.viewNode"
  >
    <v-card outlined class="main-content" >
      <v-card-title>
        <v-chip >{{ viewNode.label}}</v-chip>
        <v-spacer/>
        <i class="fas fa-window-maximize" v-on:click="fullscreen = !fullscreen"></i>
        &nbsp;
        <i class="fas fa-times-circle" v-on:click="$parent.nodeDialog = false"></i>
      </v-card-title>
      <v-card-text class="main-content" :style="'height:'+myheight+';' ">
        <NodeViewer :view-node="viewNode"></NodeViewer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import NodeViewer from "@/views/koman/parts/NodeViewer";

export default {
  name: 'koman-node-dialog',
  components: { NodeViewer },
  props: {
    viewNode: {
      type: Object, default: function () { return null }
    }
  },
  data(){
    return {
      fullscreen: false,
      myheight: "700px",
    }
  },
  watch: {
    fullscreen: function(newval,oldval){
      this.myheight = newval ? "100%": "700px"
    }
  },
  methods: {
  }
}
</script>

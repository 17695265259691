import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=47a4c6bf&scoped=true&"
import script from "./Sidebar.vue?vue&type=script&lang=js&"
export * from "./Sidebar.vue?vue&type=script&lang=js&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=47a4c6bf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a4c6bf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBottomSheet,VBtn,VCard,VDialog,VDivider,VList,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer,VSubheader,VToolbar,VToolbarTitle})

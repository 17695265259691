<style scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.toolbar {
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
}

.timeline {
  width: 100%;
  height: 100%;
}

</style>

<template>
  <div class="main" ref="main">
    <v-chip-group class="toolbar">
      <v-chip color="green" small v-on:click="fit">fit</v-chip>
      <v-chip color="green" small v-on:click="getVisibleItems">visible items</v-chip>
    </v-chip-group>
    <div ref="visualization" class="timeline"></div>
  </div>
</template>

<script>
import vis from "vis-timeline/dist/vis-timeline-graph2d.min"
import "vis-timeline/dist/vis-timeline-graph2d.css"

import { getHTMLElementDimensions } from "@/helpers/index"

export default {
  name: 'visual-timeline',
  props: {
    incomingEvents: { Array, default : function(){ return [
        {id: 1, content: 'item 1', start: '2014-04-20'},
        {id: 2, content: 'item 2', start: '2014-04-14'},
        {id: 3, content: 'item 3', start: '2014-04-18'},
        {id: 4, content: 'item 4', start: '2014-04-16', end: '2014-04-19'},
        {id: 5, content: 'item 5', start: '2014-04-25'},
        {id: 6, content: 'item 6', start: '2014-04-27', type: 'point'}
      ]}}
  },
  components: { },
  data: () => ({
    timeline: null
  }),
  watch: {
  },
  mounted() {
    this.renderTimeline()
  },
  methods: {
    fit(){
      this.timeline.fit()
    },
    getVisibleItems(){
      console.log(this.timeline.getVisibleItems())
    },
    renderTimeline(){
      // DOM element where the Timeline will be attached
      let container = this.$refs.visualization;
      let dimensions = getHTMLElementDimensions(container);

      // Create a DataSet (allows two way data-binding)
      console.log(this.incomingEvents)
      let items = new vis.DataSet(this.incomingEvents);

      // Configuration for the Timeline
      var options = {
        autoResize: true,
        minHeight: dimensions.height,
        cluster: false,
        verticalScroll:  true,
        selectable: false,
        showWeekScale: true
      };
      // Create a Timeline
      this.timeline = new vis.Timeline(container, items, options);
      this.timeline.on('click', function (properties) {
        console.log('click', properties);
      });

      this.timeline.on('rangechanged', (properties)=> {
        console.log('rangechanged', properties);
        this.getVisibleItems()
      });

    }
  }

}
</script>

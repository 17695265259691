<style scoped>
.main-content {
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<template>
  <!-- node dialog -->
  <v-dialog
      v-model="dialog"
      width="100%"
      scrollable
      :fullscreen="fullscreen"
  >
    <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" :icon="isMobile ? true : false" :plain="!isMobile ? true : false" >
          <i class="fas fa-book-open"></i> {{ !isMobile ? "Noeuds" : "" }}
        </v-btn>
    </template>
    <v-card outlined class="main-content" >
      <v-card-title>
        <v-text-field v-model="filterPages" light dense hide-details v-on:keyup.enter="submitSearch">
        </v-text-field>
        <v-spacer/>
        <i class="fas fa-window-maximize" v-on:click="fullscreen = !fullscreen"></i>
        <i class="fas fa-times-circle" v-on:click="dialog = false"></i>
      </v-card-title>
      <v-card-text class="main-content">
        <v-list>
          <!--v-list-item v-for="node in pagesToDisplay" v-on:click="()=>{ $parent.pagesDialog = false; $parent.subgraphFromNode(node.data)}">
            {{node.data.label}}
          </v-list-item-->
          <v-list-item v-for="node in pagesToDisplay" v-on:click="clickNode(node)">
            {{node.data.label}}
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {isMobileOnly} from "mobile-device-detect";
import {fetchDatasources} from "@/backend/mainAPI";

export default {
  name: 'koman-node-dialog',
  components: {},
  props: {
    /**
     * Noeuds du graphe dans le format cytoscape.js
     */
    nodes: {
      type: Array, default: function () {
        return []
      }
    }
  },
  data(){
    return {
      dialog: false,
      filterPages: "",
      fullscreen: false,
      myheight: "700px",
      isMobile: isMobileOnly
    }
  },
  computed: {
    pagesToDisplay: function(){
      return this.nodes.filter((node)=>{ return node.data.label.toUpperCase().includes(this.filterPages.toUpperCase())})
    }
  },
  mounted() {

  },
  watch: {
    fullscreen: function(newval,oldval){
      this.myheight = newval ? "100%": "700px"
    }
  },
  methods: {
    clickNode(node){
      this.dialog = false;
      this.$emit("clickNode",node);
    }
  }
}
</script>

<style scoped>
.main {
  width: 100%;
  height: calc(100vh - 80px);
  padding: 0;
  margin: 0;
  overflow: hidden;
}
</style>

<template>
  <div class="main">
    <AppBar></AppBar>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card to="/koman" :title="$t('Application_Navbar_Entry_Koman_title')">
            <v-card-title>
              <i :class="$t('Application_Navbar_Entry_Koman_Icon')"></i>
              &nbsp;
              {{$t('Application_Navbar_Entry_Koman_title')}}
            </v-card-title>
            <v-card-text>
              Navigateur graphique dans des sources de données liées
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>
              <i class="fa fa-ellipsis-h"></i>
              &nbsp;
              comming soon
            </v-card-title>
            <v-card-text>
              comming soon
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppBar from "@/views/koman/parts/AppBar";
export default {
  name: 'Home',
  components: {
    AppBar
  },
  data: () => ({
  }),
  watch: {
  },
  methods: {
  }

}
</script>

<style>
</style>

<template>
    <v-system-bar window dark>
      <slot></slot>
    </v-system-bar>
</template>

<script>
    export default {
        name: 'wysygraph-top-toolbar',
        components: {},
        data(){
            return {
            }
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        watch: {
        },
        methods: {
        }
    }
</script>

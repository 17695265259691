<style>
</style>

<template>
  <v-app-bar app color="orange" dark dense>
    <v-app-bar-nav-icon v-on:click="$parent.$emit('clickAppNavIcon')"></v-app-bar-nav-icon>
    <v-toolbar-title v-if="!isMobile"><i :class="$t('Application_Navbar_Entry_Koman_Icon')"></i> &nbsp;{{$t('Application_Navbar_Entry_Koman_title')}}</v-toolbar-title>
    <slot></slot>
    <v-spacer></v-spacer>
    <v-menu left bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>Profil</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {isMobileOnly} from "mobile-device-detect";

export default {
  name: 'koman-app-bar',
  components: {},
  data(){
    return {
      isMobile: isMobileOnly
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style>
/* https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightslategray;
}

.blink_me {
  color: red !important;
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
body {
  height: 100vh;
  overflow: hidden;
}
</style>

<template>
  <fullscreen ref="fullscreen" @change="fullscreenChange">
  <v-app>
    <notifications group="foo" position="bottom right"/>
    <Sidebar :open-trigger="sidebar">
      <v-list dense>
        <v-list-item link @click="toggle" :title="$t('Application_Navbar_Entry_Fullscreen_title')">
          <v-list-item-icon>
            <i :class="fullscreen ? $t('Application_Navbar_Entry_FullscreenExit_icon') : $t('Application_Navbar_Entry_Fullscreen_icon')"></i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{$t('Application_Navbar_Entry_Fullscreen_title')}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </Sidebar>
    <v-main>
      <router-view @clickAppNavIcon="sidebar = Date.now()"/>
    </v-main>
  </v-app>
  </fullscreen>
</template>

<script>

import {isMobileOnly} from "mobile-device-detect";

import Sidebar from "@/components/common/Sidebar";

export default {
  name: 'App',
  components: {
    Sidebar
  },
  data: () => ({
    sidebar: null,
    isMobile: isMobileOnly,
    fullscreen: false
  }),
  watch: {
  },
  mounted() {
    if(this.isMobile) this.showFaceLeft = false;
  },
  methods: {
    toggle () {
      this.$refs['fullscreen'].toggle() // recommended
      // this.fullscreen = !this.fullscreen // deprecated
    },
    fullscreenChange (fullscreen) {
      console.log(fullscreen)
      this.fullscreen = fullscreen
    },
  }
};
</script>

let endpoint = process.env.VUE_APP_API;

/**
 *
 * @param $http
 * @param apiEntry
 * @param params
 * @param callback
 * @param callbackError
 */
const apiSimplex = ($http,apiEntry,params,callback,callbackError)=>{
    $http.get(apiEntry,{
        params: params ? params : {},
    }).then(
        (response) => {
            if(callback){

                //@todo partie entièrement à supprimer
                // @todo : transformation des identifiants integer en string : pas très élégant comme solution
                //@todo pathc grossier pour eviter dans les rendu neo4J que les numéros de noeuds soient identiques aux numéros de edges
                let result = response.data;
                if(response.data.nodes && Array.isArray(response.data.nodes)) {
                    let nodeIds = [];
                    let edgeIds = [];
                    let graph = {
                        nodes: [],
                        relationships: []
                    }
                    response.data.nodes.forEach((node) => {
                        node.data.id = node.data.id.toString();
                        node.id = node.id.toString()
                        if(nodeIds.indexOf(node.data.id)>=0){
                        }else{
                            graph.nodes.push(node);
                            nodeIds.push(node.data.id)
                        }
                    });
                    if(response.data.relationships && Array.isArray(response.data.relationships)) {
                        response.data.relationships.forEach((edge)=>{
                            edge.data.id = "rel_"+edge.data.id.toString(); edge.id = "rel_"+edge.id.toString() ; edge.data.source = edge.data.source.toString(); edge.data.target = edge.data.target.toString();
                            if(edgeIds.indexOf(edge.data.id)>=0){
                            }else{
                                graph.relationships.push(edge);
                                edgeIds.push(edge.data.id)
                            }

                        })
                    }
                    result = graph;
                }
                //callback(response.data);
                callback(result);
            } else{ console.log("Default API Callback :",response.data)}
        },
        (error) => {
            console.log("Default API Callback Error :",error);
            if(callbackError) callbackError(error)
        }
    );
};

/**
 *
 * @param $http
 * @param callback
 * @param callbackError
 */
export const fetchDatasources = ($http, callback, callbackError) => {
    apiSimplex($http,endpoint+'api/graphdatasources',null,callback,callbackError);
};

/**
 *
 * @param $http
 * @param datasourceid
 * @param search
 * @param callback
 * @param callbackError
 */
export const submitSearch =($http, datasourceid,search, callback, callbackError) =>{
    apiSimplex($http,endpoint+'api/'+datasourceid+'/subgraph/'+search,null,callback,callbackError);
};

/**
 *
 * @param $http
 * @param datasourceid
 * @param callback
 * @param callbackError
 */
export const allNodes = ($http, datasourceid, callback, callbackError) =>{
    apiSimplex($http,endpoint+'api/'+datasourceid+'/nodes',null,callback,callbackError);
}

/**
 *
 * @param $http
 * @param datasourceid
 * @param filter
 * @param callback
 * @param callbackError
 */
export const subgraphFromNode = ($http, datasourceid,filter, callback, callbackError)=>{
    apiSimplex($http,endpoint+'api/'+datasourceid+'/subgraphFromNode/'+filter,null,callback,callbackError);
}

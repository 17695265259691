export const styles = [
    {
      "selector": "node",
      "style": {
        "shape": "data(shape)",
        "label": "data(label)",
        "height": 15,
        "width": 15,
        "background-color": "data(color)",
        "font-size": "8px",
        // "text-valign": "center",
        // "text-halign": "center",
        // "text-outline-color": "#000",
        // "text-outline-width": "1px",
        "color": "data(colorLabel)",
        "text-background-color": "data(colorBackgroundLabel)",
        "text-background-opacity": "data(colorBackgroundOpacityLabel)",
        "overlay-padding": "6px",
        "z-index": "10",
        'border-width': "data(borderWidth)",
        'border-color': "data(borderColor)"
      }
    },
    {
      "selector": "node[points]",
      "style": {
        "shape-polygon-points": "data(points)",
        "label": "polygon\n(custom points)",
        "text-wrap": "wrap"
      }
    },
    {
      "selector": ":parent",
      "css": {
        "text-valign": "top",
        "text-halign": "center",
        "background-color": "data(color)",
        "font-size": "10px",
        "opacity": "0.9",
        'border-width': 2,
        'border-color': 'black'
      }
    },
    {
      "selector": ".autorotate",
      "style": {
        "edge-text-rotation": "autorotate"
      }
    },
    {
      "selector": "edge",
      "css": {
        "width": 1,
        "content": "data(label)",
        "curve-style": "bezier",
        "target-arrow-shape": "triangle",
        "font-size": "8px",
        "color": "#000",
        'line-color': 'data(color)',
        "overlay-padding": "3px",
        "z-index": "10",
        "text-background-color": "#eee",
        "text-background-opacity": 1
      },
    },
// some style for the extension

  {
    selector: '.eh-handle',
    style: {
      'background-color': 'red',
      'width': 12,
      'height': 12,
      'shape': 'ellipse',
      'overlay-opacity': 0,
      'border-width': 12, // makes the handle easier to hit
      'border-opacity': 0
    }
  },

  {
    selector: '.eh-hover',
    style: {
      'background-color': 'red'
    }
  },

  {
    selector: '.eh-source',
    style: {
      'border-width': 2,
      'border-color': 'red'
    }
  },

  {
    selector: '.eh-target',
    style: {
      'border-width': 2,
      'border-color': 'red'
    }
  },

  {
    selector: '.eh-preview, .eh-ghost-edge',
    style: {
      'background-color': 'red',
      'line-color': 'red',
      'target-arrow-color': 'red',
      'source-arrow-color': 'red'
    }
  },

  {
    selector: '.eh-ghost-edge.eh-preview-active',
    style: {
      'opacity': 0
    }
  }
  ];
